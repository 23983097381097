<template>
  <div
    class="fixed bottom-0 left-0 top-[300px] z-10 w-screen cursor-default bg-black opacity-50 hover:hidden"
    :class="{ hidden: isOverlayHidden }"
  />
  <div
    id="brands-container"
    class="absolute left-0 right-0 z-[50] overflow-x-hidden bg-white py-20 shadow-lg"
  >
    <div
      class="mb-12 flex justify-center gap-3 overflow-x-hidden"
      id="alphabet-list"
    >
      <div
        v-for="letter in sortedLetters"
        class="h2 alphabet-item-desktop text-black"
        :key="letter"
        @click="scrollToGroup(letter)"
      >
        {{ letter }}
      </div>
    </div>
    <div class="flex flex-col gap-5 overflow-x-hidden pl-16">
      <div v-for="letter in sortedLetters" :key="letter">
        <div class="relative flex items-start gap-10">
          <div
            :id="`group-${letter}`"
            class="h4 flex h-[50px] w-[68px] items-center justify-center bg-[#f5f5f5]"
          >
            {{ letter }}
          </div>

          <ul id="single-brand" class="flex gap-14">
            <NuxtLink
              v-for="address in contactGroups[letter]"
              :key="address.id"
              class="h4 flex cursor-pointer items-center hover:[color:--main]"
              :to="{
                path: '/products',
                query: { brands: address.id },
              }"
              @click="
                $parent?.$parent?.$emit('toggle', false),
                  handleBrandClick(address.id)
              "
            >
              {{ address.name }}
            </NuxtLink>
          </ul>
          <hr class="absolute bottom-0 w-full md:left-[90px] lg:left-[100px]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  Brand,
  FormattedAddress,
} from "~/types/InformativePages/Header/CategoryBundleBrand";
import { useFiltersStore } from "~/store/Features/ProductManagement/filtersStore";

const props = defineProps({
  brands: {
    type: Array as PropType<Brand[]>,
    default: () => [],
  },
  layout: { type: Number, default: 1 },
});

const contactGroups = ref<Record<string, FormattedAddress[]>>({});
const sortedLetters = ref<string[]>([]);
const formattedAddresses = ref<FormattedAddress[]>([]);
const isOverlayHidden = ref(false);
const route = useRoute();
const filterStore = useFiltersStore();

const reFormatAddresses = () => {
  if (props.brands && props.brands.length > 0) {
    formattedAddresses.value = props.brands.map((brand) => ({
      name: brand.title,
      letter: brand.title.charAt(0).toLocaleUpperCase(),
      id: brand.id,
    }));
    sortContacts();
  }
};

const sortContacts = () => {
  if (formattedAddresses.value.length > 0) {
    sortedLetters.value = [
      ...new Set(formattedAddresses.value.map((address) => address.letter)),
    ].sort();

    sortedLetters.value.forEach((letter) => {
      contactGroups.value[letter] = formattedAddresses.value.filter(
        (address) => address.letter === letter,
      );
    });
  }
};

const scrollToGroup = (letter: string) => {
  const groupContainer = document.getElementById(`group-${letter}`);
  if (groupContainer) {
    groupContainer.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }
};

const handleScroll = () => {
  const scrollY = window.scrollY;
  const threshold = 100;
  isOverlayHidden.value = scrollY > threshold;
};

const handleBrandClick = (brandId: string) => {
  const currentPath = route.path;
  if (currentPath === "/products") {
    filterStore.clearAllFilters();
    filterStore.applyFilter("brands", brandId);
  }
};

onMounted(() => {
  reFormatAddresses();
  window.addEventListener("scroll", handleScroll);
});

watch(
  () => props.brands,
  (newBrands) => {
    if (newBrands && newBrands.length > 0) {
      reFormatAddresses();
    }
  },
  { immediate: true },
);
</script>
